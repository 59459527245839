import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { uniq } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../axios-provider";
import { ErrorAlertSnackbar } from "../components/AlertSnackbar";
import { LanguageSelector } from "../components/LanguageSelector";
import { Loader } from "../components/loader/Loader";
import { getCompareCurrent, getCompareLive } from "../data/items";
import { components } from "../data/items-v2.types";
import { useCustomQuery } from "../hooks/use-custom-query";
import { RoleContext } from "../role-provider";
import {
  FormattedItemCompareDetails,
  ItemCompareLiveMappedItem,
  ItemCompareLiveMappedMutex,
  ItemCompareLiveMappedProduct,
  formatItemCompare,
} from "../utils";
import {
  localizedNumStringOrEmpty,
  localizedNumStringOrNA,
  localizedNumStringOrPlaceholder,
  standardizedNumStringOrNA,
} from "../util/number-localization";

type ItemTextAttributeName = keyof Omit<
  FormattedItemCompareDetails,
  | "attachItemHeroImage"
  | "attachItemThumbnailImage"
  | "attachTransparentIconImage"
  | "categories"
  | "goesWellWith"
  | "coops"
  | "components"
  | "relationTypes"
  | "nutrientFacts"
  | "logo1"
  | "logo2"
  | "logo3"
>;
type ItemImageAttributeName =
  | "attachItemThumbnailImage"
  | "attachItemHeroImage"
  | "attachTransparentIconImage"
  | "attachMealBundleImage";
type ItemLogoAttributeName = "logo1" | "logo2" | "logo3";
type Nutrient = Omit<
  components["schemas"]["Nutrient"],
  "nutrientNameId" | "id"
>;

export const ItemCompareWithLive = () => {
  const { itemId } = useParams();
  const { selectedCountry, defaultLanguage } =
    useContext(RoleContext);
  const { apiClient } = useAxios();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    defaultLanguage
  );

  if (!itemId) {
    throw new Error("itemId is required");
  }

  const compareLiveQuery = useCustomQuery(
    [
      "item/getCompareLive",
      { itemId, countryCode: selectedCountry, languageCode: selectedLanguage },
    ],
    () =>
      getCompareLive(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const liveCompareData = useMemo(() => {
    if (compareLiveQuery.data?.data.item) {
      return formatItemCompare(compareLiveQuery.data.data.item);
    }
    return undefined;
  }, [compareLiveQuery.data]);
  const compareCurrentQuery = useCustomQuery(
    [
      "item/getCompareCurrent",
      { itemId, countryCode: selectedCountry, languageCode: selectedLanguage },
    ],
    () =>
      getCompareCurrent(apiClient)({
        itemId,
        countryCode: selectedCountry!,
        languageCode: selectedLanguage!,
      })
  );
  const currentCompareData = useMemo(() => {
    if (compareCurrentQuery.data?.data.item) {
      return formatItemCompare(compareCurrentQuery.data.data.item);
    }
    return undefined;
  }, [compareCurrentQuery.data]);

  const nutrientFactNames = useMemo(() => {
    const liveNutrientNames = liveCompareData
      ? liveCompareData.nutrientFacts.nutrient.map(
        (liveNutrient) => liveNutrient.name
      )
      : [];
    const currentNutrientNames = currentCompareData
      ? currentCompareData.nutrientFacts.nutrient.map(
        (currentNutrient) => currentNutrient.name
      )
      : [];
    return uniq([...liveNutrientNames, ...currentNutrientNames]);
  }, [liveCompareData, currentCompareData]);

  const nutritionLabels = useMemo(() => {
    const live = liveCompareData ? liveCompareData.nutrientFacts.nutrient : [];
    const current = currentCompareData
      ? currentCompareData.nutrientFacts.nutrient
      : [];

    const liveMap = new Map(live.map((item) => [item.id, item]));

    const merged = current.map((currentItem) => {
      const liveItem = liveMap.get(currentItem.id);
      // If `uom` is an empty string in the current item, try to get it from the live item
      if (currentItem.uom === "" && liveItem) {
        return { ...currentItem, uom: liveItem.uom };
      }
      return currentItem;
    });

    // Include any live items not present in current
    live.forEach((liveItem) => {
      if (!merged.some((currentItem) => currentItem.id === liveItem.id)) {
        merged.push(liveItem);
      }
    });

    return merged;
  }, [currentCompareData, liveCompareData]);
  // ITEM NAME
  const currentItemName = useMemo(() => {
    return currentCompareData?.itemName ?? "";
  }, [currentCompareData]);

  // MAPPED ELEMENTS (Product/Optional Item/Mutex)
  const liveProductMappings = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.mappedProducts;
    }
  }, [liveCompareData]);
  const liveItemMappings = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.mappedItems;
    }
  }, [liveCompareData]);
  const liveMutexMappings = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.mappedMutexGroups;
    }
  }, [liveCompareData]);
  const liveMappedElementCount: number = useMemo(() => {
    const mappedProductCount = liveProductMappings
      ? liveProductMappings.length
      : 0;
    const mappedOptionalItemCount = liveItemMappings
      ? liveItemMappings.length
      : 0;
    const mappedMutexCount = liveMutexMappings ? liveMutexMappings.length : 0;
    return mappedProductCount + mappedOptionalItemCount + mappedMutexCount;
  }, [liveProductMappings, liveItemMappings, liveMutexMappings]);

  const currentProductMappings = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.mappedProducts;
    }
  }, [currentCompareData]);
  const currentItemMappings = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.mappedItems;
    }
  }, [currentCompareData]);
  const currentMutexMappings = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.mappedMutexGroups;
    }
  }, [currentCompareData]);
  const currentMappedElementCount: number = useMemo(() => {
    const mappedProductCount = currentProductMappings
      ? currentProductMappings.length
      : 0;
    const mappedOptionalItemCount = currentItemMappings
      ? currentItemMappings.length
      : 0;
    const mappedMutexCount = currentMutexMappings
      ? currentMutexMappings.length
      : 0;
    return mappedProductCount + mappedOptionalItemCount + mappedMutexCount;
  }, [currentProductMappings, currentItemMappings, currentMutexMappings]);

  // MAPPED CATEGORIES
  const liveCategories = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.categories.categories;
    }
  }, [liveCompareData]);
  const currentCategories = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.categories.categories;
    }
  }, [currentCompareData]);

  // GOES WELL WITH
  const liveGoesWellWith = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.goesWellWith.goesWell;
    }
  }, [liveCompareData]);
  const currentGoesWellWith = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.goesWellWith.goesWell;
    }
  }, [currentCompareData]);

  // CO-OP MAPPINGS
  const liveCoOps = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.coops.coop;
    }
  }, [liveCompareData]);
  const currentCoOps = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.coops.coop;
    }
  }, [currentCompareData]);

  // RELATIONSHIP MAPPINGS
  const liveRelationshipMappings = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.relationTypes.relationType;
    }
  }, [liveCompareData]);
  const currentRelationshipMappings = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.relationTypes.relationType;
    }
  }, [currentCompareData]);

  // NUTRIENTS
  const liveNutrients = useMemo(() => {
    if (liveCompareData) {
      return liveCompareData.nutrientFacts.nutrient;
    }
  }, [liveCompareData]);
  const currentNutrients = useMemo(() => {
    if (currentCompareData) {
      return currentCompareData.nutrientFacts.nutrient;
    }
  }, [currentCompareData]);

  const isFetching = useMemo(
    () =>
      Boolean(compareLiveQuery.isFetching || compareCurrentQuery.isFetching),
    [compareLiveQuery.isFetching, compareCurrentQuery.isFetching]
  );
  const getLiveAttributeValue = useCallback(
    (key: ItemTextAttributeName | ItemLogoAttributeName) => {
      if (liveCompareData) {
        const value = liveCompareData[key];
       // console.log(`live Attribute [${key}]:`, value);
        if (Array.isArray(value) && value.length === 0) {
          return "";
        }
        if (value === null || value === undefined) {
          return "";
        }
        if (typeof value === 'object') {
          return Object.keys(value).length === 0 ? "" : JSON.stringify(value, null, 2); // Convert objects to JSON string
        }
        return String(value);
      }
      return "";
  },
  [liveCompareData]
);

  const getCurrentAttributeValue = useCallback(
    (key: ItemTextAttributeName | ItemLogoAttributeName) => {
      if (currentCompareData) {
        const value = currentCompareData[key];
       // console.log(`Current Attribute [${key}]:`, value);
        if (Array.isArray(value) && value.length === 0) {
          return "";
        }
        if (value === null || value === undefined) {
          return "";
        }
        if (typeof value === 'object') {
          return Object.keys(value).length === 0 ? "" : JSON.stringify(value, null, 2);// Convert objects to JSON string
        }
        return String(value);
      }
      return "";
    },
    [currentCompareData]
  );

  const pageTitle = useMemo(() => {
    const itemName = getCurrentAttributeValue("itemName");
    
    return itemName ? `Compare With Live (${itemName})` : `Compare With Live`;
  }, [getCurrentAttributeValue]);

  const getLiveLogo = useCallback(
    (key: ItemLogoAttributeName) => {
      //changed  for Platform 9440 added undefined check condition by amanjare
      if (liveCompareData && liveCompareData[key]) {
        return liveCompareData[key];
      }
      return { url: "", altText: "" };
    },
    [liveCompareData]
  );
  const getCurrentLogo = useCallback(
    (key: ItemLogoAttributeName) => {
      //changed  for Platform 9440 added undefined check condition by amanjare
      if (currentCompareData && currentCompareData[key]) {
        return  currentCompareData[key];
      }
      return { url: "", altText: "" };
    },
    [currentCompareData]
  );


  const getLiveImageValue = useCallback(
    (imageKey: ItemImageAttributeName) => {
      let imageReturnVal = "--";
      if (liveCompareData && liveCompareData[imageKey]) {
        const liveImage = liveCompareData[imageKey];
        if (
          liveImage !== null &&
          typeof liveImage["imageName"] === "string" &&
          liveImage["imageName"].length > 0
        ) {
          imageReturnVal = liveImage["imageName"];
        }
      }
      return imageReturnVal;
    },
    [liveCompareData]
  );

  const getCurrentImageValue = useCallback(
    (imageKey: ItemImageAttributeName) => {
      let imageReturnVal = "--";
      if (currentCompareData && currentCompareData[imageKey]) {
        const currentImage = currentCompareData[imageKey];
        if (
          currentImage !== null &&
          typeof currentImage["imageName"] === "string" &&
          currentImage["imageName"].length > 0
        ) {
          imageReturnVal = currentImage["imageName"];
        }
      }
      return imageReturnVal;
    },
    [currentCompareData]
  );

  const getAttributeRow = useCallback(
    (key: ItemTextAttributeName, fieldName: string) => {
      const liveValue = getLiveAttributeValue(key);
      const currentValue = getCurrentAttributeValue(key);

      const renderValue = (value: string) => {
        return value === "[Object Object]" || value === "{}" ? "" : value;
      };

      if (typeof liveValue !== "string" && typeof currentValue !== "string") {
        // render empty if both live & current are blank
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      if (typeof currentValue === "string" && typeof liveValue !== "string") {
        // show changed if current has value but live is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#FCEFCC",
              }}
            >
              {/* {typeof currentValue === "string"
                ? currentValue
                : JSON.stringify(currentValue)} */}
                 {renderValue(currentValue)}
            </TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
      if (typeof liveValue === "string" && typeof currentValue !== "string") {
        // show changed if live has value but current is empty
        return (
          <StyledTableRow
            key={key}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              {fieldName}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#FCEFCC",
              }}
            ></TableCell>
            <TableCell>
              {/* {typeof liveValue === "string"
                ? liveValue
                : JSON.stringify(liveValue)} */}
                 {renderValue(liveValue)}
            </TableCell>
          </StyledTableRow>
        );
      }
      // both live and current have value - show changed if values do not match
      return (
        <StyledTableRow
          key={key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {fieldName}
          </TableCell>
          <TableCell
            sx={{
              backgroundColor:
                currentValue?.trim() !== liveValue?.trim()
                  ? "#FCEFCC"
                  : "white",
            }}
          >
            {/* {typeof currentValue === "string"
              ? currentValue
              : JSON.stringify(currentValue)} */}
               {renderValue(currentValue)}
          </TableCell>
          <TableCell>
            {/* {typeof liveValue === "string"
              ? liveValue
              : JSON.stringify(liveValue)} */}
               {renderValue(liveValue)}
          </TableCell>
        </StyledTableRow>
      );
    },
    [getLiveAttributeValue, getCurrentAttributeValue]
  );

  const doesAttributeExist = useCallback(
    (key: ItemTextAttributeName) => {
      const liveValue = getLiveAttributeValue(key);
      const currentValue = getCurrentAttributeValue(key);
      return (
        typeof liveValue === "string" &&
        typeof currentValue === "string" &&
        (liveValue.length > 0 || currentValue.length > 0)
      );
    },
    [getLiveAttributeValue, getCurrentAttributeValue]
  );
  const getLiveNutrientValue = useCallback(
    (nutrientName: string, fieldName: keyof Nutrient) => {
      if (liveNutrients) {
        const nutrient = liveNutrients.find((n) => n.name === nutrientName);
        if (nutrient) {
          return nutrient[fieldName];
        }
      }
      return "";
    },
    [liveNutrients]
  );
  const getCurrentNutrientValue = useCallback(
    (nutrientName: string, fieldName: keyof Nutrient) => {
      if (currentNutrients) {
        const nutrient = currentNutrients.find((n) => n.name === nutrientName);
        if (nutrient) {
          return nutrient[fieldName];
        }
      }
      return "";
    },
    [currentNutrients]
  );
  const formatUomValues = useCallback(
    (nutrientName: string, fieldName: keyof Nutrient) => {
      if (nutritionLabels) {
        const nutrient = nutritionLabels.find((n) => n.name === nutrientName);
        if (nutrient) {
          return nutrient[fieldName];
        }
      }
      return "";
    },
    [nutritionLabels]
  );

  const getMappedProductRow = (
    mappedProduct: ItemCompareLiveMappedProduct,
    key: string
  ) => {
    return (
      <StyledTableRow
        key={key}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{mappedProduct.productName}</TableCell>
        <TableCell>Product</TableCell>
        <TableCell>
          {localizedNumStringOrEmpty(mappedProduct.quantity, selectedCountry!)}
        </TableCell>
        <TableCell>{mappedProduct.unit}</TableCell>
        <TableCell>{mappedProduct.productAllergen}</TableCell>
        <TableCell>{mappedProduct.ingredient}</TableCell>
      </StyledTableRow>
    );
  };
  const getMappedItemRow = (
    mappedItem: ItemCompareLiveMappedItem,
    key: string
  ) => {
    return (
      <StyledTableRow
        key={key}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>{mappedItem.itemName}</TableCell>
        <TableCell>{mappedItem.type}</TableCell>
        <TableCell>
          {localizedNumStringOrEmpty(mappedItem.quantity, selectedCountry!)}
        </TableCell>
        <TableCell>{mappedItem.unit}</TableCell>
        <TableCell>--</TableCell>
        <TableCell>--</TableCell>
      </StyledTableRow>
    );
  };
  const getMappedMutexRow = (
    mappedMutexGroup: ItemCompareLiveMappedMutex,
    key: string
  ) => {
    return (
      <StyledTableRow
        key={key}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          {mappedMutexGroup.name || mappedMutexGroup.itemName}
        </TableCell>
        <TableCell>{mappedMutexGroup.type}</TableCell>
        <TableCell>--</TableCell>
        <TableCell>--</TableCell>
        <TableCell>
          {mappedMutexGroup.item_allergen || mappedMutexGroup.product_allergen}
        </TableCell>
        <TableCell>
          {mappedMutexGroup.ingredientStatement ||
            mappedMutexGroup.item_ingredient_statement}
        </TableCell>
      </StyledTableRow>
    );
  };

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
    marketing: true,
    mapping: true,
    category: true,
    goesWell: true,
    regionCoop: true,
    relationship: true,
    nutrition: true,
  });

  const toggleTable = (tableName: keyof typeof isOpen) => {
    setIsOpen({
      ...isOpen,
      [tableName]: !isOpen[tableName],
    });
  };

  const MarketingInformationTable = () => {
    const displayPimId = (selectedCountry === 'UK'
      || selectedCountry === 'DE')
    const displayGenesisFields = (selectedCountry === 'UK'
        || selectedCountry === 'CA'|| selectedCountry === 'IE')  
    const currentLogo1 = getCurrentLogo("logo1");
    const liveLogo1 = getLiveLogo("logo1");
    const currentLogo2 = getCurrentLogo("logo2");
    const liveLogo2 = getLiveLogo("logo2");
    const currentLogo3 = getCurrentLogo("logo3");
    const liveLogo3 = getLiveLogo("logo3");
    const itemType=getCurrentAttributeValue("itemType");
    const { isMultipleRFMIds } = useContext(RoleContext);
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <TableContainer component={Paper}>
          <Table aria-label="Marketing Information Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Marketing Information</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Live Value</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {getAttributeRow("itemType", "Item Type")}
              {/*  changed for platform 9440 start amanjare  */}
              {itemType && itemType==='Item Collection' && getAttributeRow("timeOfDay", "Time Of Day")}
              {/* changed for platform 9440 end amanjare */}
              {getAttributeRow("itemName", "Item Name")}
              {getAttributeRow("itemMarketingName", "Item Marketing Name")}
              {getAttributeRow("itemId", "Item ID")}
              {getAttributeRow("shortName", "Short Name")}
              {getAttributeRow(
                "externalId",
                "RFM Id / Menu Item No / External Id / Import Id"
              )}
              {isMultipleRFMIds === "Y" &&
                getAttributeRow(
                  "externalId2",
                  "RFM Id 2 / Menu Item No / External Id / Import Id"
                )}
              {isMultipleRFMIds === "Y" &&
                getAttributeRow(
                  "externalId3",
                  "RFM Id 3 / Menu Item No / External Id / Import Id"
                )}
              {doesAttributeExist("genesisMenuItemNo") &&
                getAttributeRow("genesisMenuItemNo", "Genesis Menu Item No.")}
              {getAttributeRow("doNotShow", "Do Not Show")}
              {/* {doesAttributeExist("pimId") &&
                getAttributeRow("pimId", "PIM ID")}
              {doesAttributeExist("pimItemName") &&
                getAttributeRow("pimItemName", "PIM Item Name")} */}

              {displayPimId && getAttributeRow("pimId", "PIM ID")}
              {displayPimId &&
                getAttributeRow("pimItemName", "PIM Item Name")}
              {displayGenesisFields &&
                getAttributeRow("genesisId", "Genesis ID")}

              {displayGenesisFields &&
                getAttributeRow("genesisItemName", "Genesis Item Name")}

              {doesAttributeExist("importId") &&
                getAttributeRow("importId", "Import ID")}
              {doesAttributeExist("importedItemName") &&
                getAttributeRow("importedItemName", "Imported Item Name")}

              {getAttributeRow("serves", "Serves")}
              {getAttributeRow("description", "Description")}
              {getAttributeRow("itemComments", "Item Comments")}
              {getAttributeRow("keywords", "Keywords")}
              {getAttributeRow(
                "itemIngredientStatement",
                "Ingredient Statement"
                )}
              {getAttributeRow(
                "itemAdditionalTextIngredientStatement",
                "Additional Text For Ingredient Statement"
                )}
              {getAttributeRow("itemAllergen", "Item Allergen")}
              {getAttributeRow("itemAdditionalAllergen", "Additional Allergen")}
              {getAttributeRow(
                "nutritionQualityStatementText",
                "Nutrition Quality Statement Text"
                )}
              {getAttributeRow("itemMetaTitle", "Item Meta Title")}
              {getAttributeRow("itemMetaDescription", "Item Meta Description")}
              {getAttributeRow("customizationLabel", "Customization Label")}
              {getAttributeRow("customizationButton", "Customization Button")}
              {getAttributeRow("itemStatusFlag", "Status Flag")}
              {getAttributeRow("showProductImages", "Show Product Images?")}
              {getAttributeRow("specializationText1", "Specialization Text 1")}
              {getAttributeRow("specializationText2", "Specialization Text 2")}
              {getAttributeRow("sitemap", "SiteMap")}
              {getAttributeRow("frequency", "Frequency")}
              {getAttributeRow("priority", "Priority")}
              {getAttributeRow("isFortifiedMenuItem", "Is Fortified Menu Item")}
              {getAttributeRow("hasComponents", "Has Components")}
              {currentLogo1 && liveLogo1 && (
                <>
                  <StyledTableRow>
                    <TableCell>Logo 1 URL</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo1.url?.trim() !== liveLogo1.url?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo1.url}
                    </TableCell>
                    <TableCell>{liveLogo1?.url}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Logo 1 Alt Text</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo1.altText?.trim() !==
                            liveLogo1.altText?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo1.altText}
                    </TableCell>
                    <TableCell>{liveLogo1?.altText}</TableCell>
                  </StyledTableRow>
                </>
              )}

              {currentLogo2 && liveLogo2 && (
                <>
                  <StyledTableRow>
                    <TableCell>Logo 2 URL</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo2.url?.trim() !== liveLogo2.url?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo2.url}
                    </TableCell>
                    <TableCell>{liveLogo2?.url}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Logo 2 Alt Text</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo2.altText?.trim() !==
                            liveLogo2.altText?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo2.altText}
                    </TableCell>
                    <TableCell>{liveLogo2?.altText}</TableCell>
                  </StyledTableRow>
                </>
              )}

              {currentLogo3 && liveLogo3 && (
                <>
                  <StyledTableRow>
                    <TableCell>Logo 3 URL</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo3.url?.trim() !== liveLogo3.url?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo3.url}
                    </TableCell>
                    <TableCell>{liveLogo3?.url}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Logo 3 Alt Text</TableCell>
                    <TableCell
                      style={{
                        backgroundColor:
                          currentLogo3.altText?.trim() !==
                            liveLogo3.altText?.trim()
                            ? "#FCEFCC"
                            : "white",
                      }}
                    >
                      {currentLogo3.altText}
                    </TableCell>
                    <TableCell>{liveLogo3?.altText}</TableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledRelativeContainer>
    );
  };

  const FootersTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid mobile={12}>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Item Footers
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Current Value</TableCell>
                  <TableCell>Live Value</TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                <StyledTableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <ul>
                      {currentCompareData?.footers?.map((footer) => (
                        <li key={footer}>{footer}</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul>
                      {liveCompareData?.footers?.map((footer) => (
                        <li key={footer}>{footer}</li>
                      ))}
                    </ul>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </StyledRelativeContainer>
    );
  };

  const ImageDetailsTable = () => {
    const currentThumbnailValue = getCurrentImageValue(
      "attachItemThumbnailImage"
    );
    const liveThumbnailValue = getLiveImageValue("attachItemThumbnailImage");
    const currentHeroValue = getCurrentImageValue("attachItemHeroImage");
    const liveHeroValue = getLiveImageValue("attachItemHeroImage");
    const currentIconValue = getCurrentImageValue("attachTransparentIconImage");
    const liveIconValue = getLiveImageValue("attachTransparentIconImage");
    const currentMealBundleValue = getCurrentImageValue(
      "attachMealBundleImage"
    );
    const liveMealBundleValue = getLiveImageValue("attachMealBundleImage");
   

    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Item Images
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Image Name</TableCell>
                <TableCell>Current Value</TableCell>
                <TableCell>Live Value</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>

              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>Thumbnail Image</TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      currentThumbnailValue?.trim() !==
                        liveThumbnailValue?.trim()
                        ? "#FCEFCC"
                        : "white",
                  }}
                >
                  {currentThumbnailValue}
                </TableCell>
                <TableCell>{liveThumbnailValue}</TableCell>
              </StyledTableRow>
              <StyledTableRow
                key=""
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>Hero Image</TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      currentHeroValue?.trim() !== liveHeroValue?.trim()
                        ? "#FCEFCC"
                        : "white",
                  }}
                >
                  {currentHeroValue}
                </TableCell>
                <TableCell>{liveHeroValue}</TableCell>
              </StyledTableRow>
              <StyledTableRow
                key=""
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>Transparent Icon Image</TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      currentIconValue?.trim() !== liveIconValue?.trim()
                        ? "#FCEFCC"
                        : "white",
                  }}
                >
                  {currentIconValue}
                </TableCell>
                <TableCell>{liveIconValue}</TableCell>
              </StyledTableRow>
              <StyledTableRow
                key=""
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>Meal Bundle Image</TableCell>
                <TableCell
                  style={{
                    backgroundColor:
                      currentMealBundleValue?.trim() !==
                        liveMealBundleValue?.trim()
                        ? "#FCEFCC"
                        : "white",
                  }}
                >
                  {currentMealBundleValue}
                </TableCell>
                <TableCell>{liveMealBundleValue}</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledRelativeContainer>
    );
  };
  const MappingTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}

        <Grid>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Current Mappings
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="Current Element Mapping Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Item/Product Name </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Allergens</TableCell>
                <TableCell>Ingredients</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {currentMappedElementCount > 0 && (
              <TableBody>
                {currentProductMappings?.map((productMapping, index) =>
                  getMappedProductRow(productMapping, `product-${index}`)
                )}
                {currentItemMappings?.map((optionalItemMapping, index) =>
                  getMappedItemRow(
                    optionalItemMapping,
                    `optional-item-${index}`
                  )
                )}
                {currentMutexMappings?.map((mutexMapping, index) =>
                  getMappedMutexRow(mutexMapping, `mutex-${index}`)
                )}
              </TableBody>
            )}
          </Table>
          {currentMappedElementCount === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No current mapped elements`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
        <Grid>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Live Mappings
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="Live Element Mapping Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Item/Product Name </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Allergens</TableCell>
                <TableCell>Ingredients</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {liveMappedElementCount > 0 && (
              <TableBody>
                {liveProductMappings?.map((productMapping, index) =>
                  getMappedProductRow(productMapping, `product-${index}`)
                )}
                {liveItemMappings?.map((optionalItemMapping, index) =>
                  getMappedItemRow(
                    optionalItemMapping,
                    `optional-item-${index}`
                  )
                )}
                {liveMutexMappings?.map((mutexMapping, index) =>
                  getMappedMutexRow(mutexMapping, `mutex-${index}`)
                )}
              </TableBody>
            )}
          </Table>
          {liveMappedElementCount === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No live mapped elements`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </StyledRelativeContainer>
    );
  };
  const CategoryMappingTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Current Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Category Id</TableCell>
                  <TableCell>Category Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {currentCategories && currentCategories.length > 0 && (
                <TableBody>
                  {currentCategories.map((category, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{category.id}</TableCell>
                      <TableCell>{category.name}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!currentCategories || currentCategories.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No current mapped categories`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Live Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Category Id</TableCell>
                  <TableCell>Category Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {liveCategories && liveCategories.length > 0 && (
                <TableBody>
                  {liveCategories.map((category, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{category.id}</TableCell>
                      <TableCell>{category.name}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!liveCategories || liveCategories.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No live mapped categories`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
      </StyledRelativeContainer>
    );
  };
  const GoesWithTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Current Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Goes Well With Id</TableCell>
                  <TableCell>Goes Well With Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {currentGoesWellWith && currentGoesWellWith.length > 0 && (
                <TableBody>
                  {currentGoesWellWith.map((goesWellWith, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{goesWellWith.id}</TableCell>
                      <TableCell>{goesWellWith.itemName}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!currentGoesWellWith || currentGoesWellWith.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No current Goes Well With items`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Live Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Goes Well With Id</TableCell>
                  <TableCell>Goes Well With Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {liveGoesWellWith && liveGoesWellWith.length > 0 && (
                <TableBody>
                  {liveGoesWellWith.map((goesWellWith, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{goesWellWith.id}</TableCell>
                      <TableCell>{goesWellWith.itemName}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!liveGoesWellWith || liveGoesWellWith.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No live Goes Well With items`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
      </StyledRelativeContainer>
    );
  };
  const CoopTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Current Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Co-Op Id</TableCell>
                  <TableCell>Co-Op Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {currentCoOps && currentCoOps.length > 0 && (
                <TableBody>
                  {currentCoOps.map((coOp, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{coOp.coopId}</TableCell>
                      <TableCell>{coOp.name}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!currentCoOps || currentCoOps.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No current Co-Op locations`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
        <Grid mobile={6}>
          <Grid>
            <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
              Live Mappings
            </Typography>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="Category Mapping Table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell>Co-Op Id</TableCell>
                  <TableCell>Co-Op Name </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              {liveCoOps && liveCoOps.length > 0 && (
                <TableBody>
                  {liveCoOps.map((coOp, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{coOp.coopId}</TableCell>
                      <TableCell>{coOp.name}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {(!liveCoOps || liveCoOps.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No live Co-Op locations`}</Typography>
              </StyledEmptyBox>
            )}
          </TableContainer>
        </Grid>
      </StyledRelativeContainer>
    );
  };
  const RelationshipMappingTable = () => {
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <Grid>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Current Relationships
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="Current Relationship Mapping Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Relationship Mapping</TableCell>
                <TableCell>Grouped Items</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {currentRelationshipMappings &&
              currentRelationshipMappings.length > 0 && (
                <TableBody>
                  {currentRelationshipMappings.map((relation, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{currentItemName}</TableCell>
                      <TableCell>{relation.type}</TableCell>
                      <TableCell>
                        {relation.relatedItems &&
                          relation.relatedItems.length && (
                            <ul>
                              {relation.relatedItems.map(
                                (relatedItem: string) => (
                                  <li>{relatedItem}</li>
                                )
                              )}
                            </ul>
                          )}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
          </Table>
          {(!currentRelationshipMappings ||
            currentRelationshipMappings.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No current relationship mappings`}</Typography>
              </StyledEmptyBox>
            )}
        </TableContainer>
        <Grid>
          <Typography variant="h4" color="#DA291C" sx={{ pt: 1 }}>
            Live Relationships
          </Typography>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="Live Relationship Mapping Table">
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Relationship Mapping</TableCell>
                <TableCell>Grouped Items</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {liveRelationshipMappings &&
              liveRelationshipMappings.length > 0 && (
                <TableBody>
                  {liveRelationshipMappings.map((relation, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{currentItemName}</TableCell>
                      <TableCell>{relation.type}</TableCell>
                      <TableCell>
                        {relation.relatedItems &&
                          relation.relatedItems.length && (
                            <ul>
                              {relation.relatedItems.map(
                                (relatedItem: string) => (
                                  <li>{relatedItem}</li>
                                )
                              )}
                            </ul>
                          )}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
          </Table>
          {(!liveRelationshipMappings ||
            liveRelationshipMappings.length === 0) && (
              <StyledEmptyBox>
                <Typography variant="h6">{`No live relationship mappings`}</Typography>
              </StyledEmptyBox>
            )}
        </TableContainer>
      </StyledRelativeContainer>
    );
  };
  const NutritionTable = () => {
    const getNutrientRow = useCallback(
      (nutrientName: string, index: number) => {
        const currentValue = localizedNumStringOrNA(
          getCurrentNutrientValue(nutrientName, "value"),
          selectedCountry!
        );
        const liveValue = localizedNumStringOrNA(
          getLiveNutrientValue(nutrientName, "value"),
          selectedCountry!
        );
        const currentValueDvRaw = localizedNumStringOrEmpty(
          getCurrentNutrientValue(nutrientName, "adultDv"),
          selectedCountry!
        );
        const currentValueDv = currentValueDvRaw === "" ? '-' : currentValueDvRaw;
        const liveValueDvRaw = localizedNumStringOrEmpty(
          getLiveNutrientValue(nutrientName, "adultDv"),
          selectedCountry!
        );
        const liveValueDv = liveValueDvRaw === "" ? '-' : liveValueDvRaw;
        const currentFloatValue = standardizedNumStringOrNA(
          currentValue,
          selectedCountry!
        );
        const liveFloatValue = standardizedNumStringOrNA(
          liveValue,
          selectedCountry!
        );
        const currentHundredGValue = localizedNumStringOrNA(
          getCurrentNutrientValue(nutrientName, "hundredGPerProduct"),
          selectedCountry!
        );
        const currentIsDisplayValue = (
          getCurrentNutrientValue(nutrientName, "isDisplay")
        );
        const liveHundredGValue = localizedNumStringOrNA(
          getLiveNutrientValue(nutrientName, "hundredGPerProduct"),
          selectedCountry!
        );
        const currentAdultDv = localizedNumStringOrPlaceholder(
          getCurrentNutrientValue(nutrientName, "adultDv"),
          selectedCountry!,
          "-"
        );
        const liveAdultDv = localizedNumStringOrPlaceholder(
          getLiveNutrientValue(nutrientName, "adultDv"),
          selectedCountry!,
          "-"
        );
        const liveIsDisplayValue = (
          getLiveNutrientValue(nutrientName, "isDisplay")
        );
        const uom = formatUomValues(nutrientName, "uom");

        const currentHundredGFloatValue = standardizedNumStringOrNA(
          currentHundredGValue,
          selectedCountry!
        );
        const liveHundredGFloatValue = standardizedNumStringOrNA(
          liveHundredGValue,
          selectedCountry!
        );
        const hasDiff =
          currentFloatValue !== liveFloatValue ||
          currentHundredGFloatValue !== liveHundredGFloatValue || currentAdultDv !== liveAdultDv;
          const hasDiffForIsDisplay =
          currentIsDisplayValue !== liveIsDisplayValue;
        return (
          <StyledTableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell>{nutrientName}</TableCell>
            <TableCell>{uom}</TableCell>
            <TableCell
              style={{
                borderLeft: "1px solid black",
                backgroundColor: hasDiff ? "#FCEFCC" : "white",
              }}
            >
              {currentValue === "N/A"
                ? currentValue
                : `${currentValue} (${currentValueDv}%)`}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: hasDiff ? "#FCEFCC" : "white",
              }}
            >
              {currentHundredGValue}
            </TableCell>
            <TableCell style={{ borderLeft: "1px solid black" }}>
              {liveValue === "N/A"
              ? liveValue
              : `${liveValue} (${liveValueDv}%)`}
            </TableCell>
            <TableCell style={{  borderRight: "1px solid black" }}>
              {liveHundredGValue}
            </TableCell>
            <TableCell
              style={{ backgroundColor: hasDiffForIsDisplay ? "#FCEFCC" : "white",
              }}
            >
              {currentIsDisplayValue}
            </TableCell>
            <TableCell style={{ borderRight: "1px solid black" }}>
              {liveIsDisplayValue}
            </TableCell>
          </StyledTableRow>
        );
      },
      []
    );
    return (
      <StyledRelativeContainer container spacing={1}>
        {isFetching && <Loader />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}></TableCell>
                <TableCell align="left" colSpan={2}>
                  Current Value
                </TableCell>
                <TableCell align="left" colSpan={2}>
                  Live Value
                </TableCell>
                <TableCell align="left" colSpan={2}>
                </TableCell>
              </TableRow>
            </TableHead>
            <StyledDashboardTableHead>
              <TableRow>
                <TableCell>Nutrition Name</TableCell>
                <TableCell>UoM</TableCell>
                <TableCell>Nutrition Value</TableCell>
                <TableCell>100g per product</TableCell>
                <TableCell>Nutrition Value</TableCell>
                <TableCell>100g per product</TableCell>
                <TableCell>Is Display Current</TableCell>
                <TableCell>Is Display Live</TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            {nutrientFactNames && nutrientFactNames.length > 0 && (
              <TableBody>
                {nutrientFactNames.map((nutrientName, index) =>
                  getNutrientRow(nutrientName, index)
                )}
              </TableBody>
            )}
          </Table>
          {(!nutrientFactNames || nutrientFactNames.length === 0) && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No nutrition information to display`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </StyledRelativeContainer>
    );
  };
  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid mobile={12}>
        <Grid mobile={12}>
          <Typography variant="h1">{pageTitle}</Typography>
        </Grid>
        <Grid
          mobile={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ backgroundColor: "#DA291C" }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid
          container
          mobile={12}
          sx={{ mb: 2, mt: 2, justifyContent: "flex-end" }}
        >
          <LanguageSelector
            selectedLanguage={selectedLanguage!}
            onChange={(language) => setSelectedLanguage(language)}
          />
        </Grid>
        <Grid mobile={12}>
          <Stack spacing={1} marginBottom="20px">
            <Grid container spacing={1}>
              <Grid mobile={12} container>
                <IconButton
                  onClick={() => toggleTable("marketing")}
                  aria-label="expand"
                >
                  {!isOpen.marketing ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2" sx={{ pt: 1 }}>
                  Marketing Information (Attributes)
                </Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.marketing}>
                  <MarketingInformationTable />
                  <ImageDetailsTable />
                  <FootersTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("mapping")}
                  aria-label="expand"
                >
                  {!isOpen.mapping ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Item Product Mapping</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.mapping}>
                  <MappingTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("category")}
                  aria-label="expand"
                >
                  {!isOpen.category ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Category Mapping</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.category}>
                  <CategoryMappingTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("goesWell")}
                  aria-label="expand"
                >
                  {!isOpen.goesWell ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Goes Well With</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.goesWell}>
                  <GoesWithTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("regionCoop")}
                  aria-label="expand"
                >
                  {!isOpen.regionCoop ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Region Co-Op Mapping</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.regionCoop}>
                  <CoopTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("relationship")}
                  aria-label="expand"
                >
                  {!isOpen.relationship ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Relationship Mapping</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.relationship}>
                  <RelationshipMappingTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ mt: 6 }}>
                <IconButton
                  onClick={() => toggleTable("nutrition")}
                  aria-label="expand"
                >
                  {!isOpen.nutrition ? (
                    <ArrowRight fontSize="large" />
                  ) : (
                    <ArrowDropDown fontSize="large" />
                  )}
                </IconButton>
                <Typography variant="h2">Item Nutrition Information</Typography>
              </Grid>
              <Grid mobile={12}>
                <Collapse in={isOpen.nutrition}>
                  <NutritionTable />
                </Collapse>
              </Grid>
              <Grid container mobile={12} sx={{ my: 2, alignItems: "center" }}>
                <Grid
                  sx={{
                    backgroundColor: "#FCEFCC",
                    height: "36px",
                    width: "80px",
                    mx: 1,
                  }}
                />
                <Typography variant="h6">Changes done in WXB</Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 50,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
