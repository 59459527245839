import { createContext, useContext, useEffect, useMemo } from "react";
import { useOutlet } from "react-router-dom";
import { useStorage } from "./hooks/use-storage";
import { AuthContext } from "./auth-provider";
import { MenuListItem, ValidLanguage, isMenuListItem, validUserMenu } from "./utils";
import { useAxios } from "./axios-provider";
import { getUserMenuList } from "./data/navmenu";
import { getEmailPreferences, showRecentFeedUpdates } from "./data/dashboard";
import { useCustomQuery } from "./hooks/use-custom-query";
import { getCountryAndRoleList } from "./data/rolecountry";

interface RoleProps {
  selectedCountry: string | null;
  selectedRole: number | null;
  defaultLanguage: string | null;
  selectableLanguages: ValidLanguage[];
  selectedRoleName: string;
  canEdit: Boolean;
  isReaderRole: Boolean;
  isLegalRole: Boolean;
  isItemLevelMarket: Boolean;
  showRecentFeedUpdates: Boolean | null;
  visibleMenuItems: string[];
  isEmailPreferenceOn: boolean;
  isMultipleRFMIds?: string;
  setIsItemLevelMarket: (market: boolean) => void;
  setSelectedCountry: (country: string) => void;
  setSelectedRole: (role: number) => void;
  setSelectableRoles: (role: selectableRoles[]) => void;
  setDefaultLanguage: (language: string) => void;
  setSelectableLanguages: (languages: ValidLanguage[]) => void;
  setShowRecentFeedUpdatesTable: (
    showRecentFeedUpdatesTable: Boolean | null
  ) => void;
  userMenuList: validUserMenu[];
}

interface selectableRoles {
  roleId?: number | undefined;
  roleName?: string | undefined;
  roleDesc?: string | undefined;
}

function getRoleNameById(roleId: number, selectableRoles: selectableRoles[]) {
  if (selectableRoles.length > 0) {
    const selectedRoleDetails = selectableRoles.find(
      (role) => role.roleId === roleId
    );
    if (selectedRoleDetails) {
      return selectedRoleDetails.roleName!;
    } else {
      throw new Error(`No role with id ${roleId}`);
    }
  } else {
    throw new Error(`No selectableRoles found ${roleId}`);
  }
}

function flattenMenuNames(menuList: MenuListItem[]): string[] {
  let names: string[] = [];

  menuList.forEach((item) => {
    names.push(item.menuName.toLowerCase());
    if (item.subMenuList.length > 0) {
      names = names.concat(flattenMenuNames(item.subMenuList));
    }
  });

  return names;
}

export const RoleContext = createContext<RoleProps>({
  selectedCountry: null,
  selectedRole: null,
  defaultLanguage: null,
  selectableLanguages: [],
  selectedRoleName: "",
  canEdit: false,
  isReaderRole: false,
  isLegalRole: false,
  isItemLevelMarket: false,
  showRecentFeedUpdates: null,
  visibleMenuItems: [],
  isEmailPreferenceOn: false,
  isMultipleRFMIds: "N",
  setIsItemLevelMarket: () => {},
  setSelectedCountry: () => {},
  setSelectedRole: () => {},
  setSelectableRoles: () => {},
  setDefaultLanguage: () => {},
  setSelectableLanguages: () => {},
  setShowRecentFeedUpdatesTable: () => {},
  userMenuList: [],
});

export const RoleProvider = () => {
  const [selectedCountry, setSelectedCountry] = useStorage(
    "selectedCountry",
    null
  );
  const [selectedRole, setSelectedRole] = useStorage("selectedRole", null);
  const [selectableRoles, setSelectableRoles] = useStorage(
    "selectableRoles",
    null
  );
  const [defaultLanguage, setDefaultLanguage] = useStorage(
    "defaultLanguage",
    null
  );
  const [selectableLanguages, setSelectableLanguages] = useStorage(
    "selectableLanguages",
    null
  );
  const [isItemLevelMarket, setIsItemLevelMarket] = useStorage(
    "isItemLevelMarket",
    null
  );
  const [showRecentFeedUpdatesTable, setShowRecentFeedUpdatesTable] =
    useStorage("showRecentFeedUpdatesTable", null);
    const [userMenuList, setUserMenuList] = useStorage("userMenuList", []);
  const selectedRoleName = useMemo(() => {
    try {
      return getRoleNameById(Number(selectedRole), selectableRoles);
    } catch (e) {
      return "";
    }
  }, [selectedRole, selectableRoles]);
  const outlet = useOutlet();
  const { accessToken, setDomain } = useContext(AuthContext);
  const { apiClient } = useAxios();

  const countryAndRoleQuery = useCustomQuery(
    ["getCountryAndRoleList", { countryCode: selectedCountry }], 
    () => getCountryAndRoleList(apiClient)({ countryCode: selectedCountry }), 
    selectedCountry !== null 
  );

  const isMultipleRFMIds = useMemo(() => {
    const countryData = countryAndRoleQuery.data?.data?.dataList && Array.isArray(countryAndRoleQuery.data.data.dataList)
      ? countryAndRoleQuery.data.data.dataList
      : [];
    
      const countryRoleData = countryData.find(
        (country: any) => country.countryCode === selectedCountry
      );
  
    return countryRoleData?.isMultipleRFMIds ?? "N"; // Default to "N"
  }, [countryAndRoleQuery.data, selectedCountry]);
  
  
  
  const menuItemsQuery = useCustomQuery(
    ["getUserMenuList", { countryCode: selectedCountry, roleId: selectedRole }],
    () =>
      getUserMenuList(apiClient)({
        countryCode: selectedCountry!,
        roleId: Number(selectedRole),
      }),
    accessToken !== null && selectedCountry !== null && Number(selectedRole) > 0
  );

  const showRecentFeedUpdatesQuery = useCustomQuery(
    ["showRecentFeedUpdates", { countryCode: selectedCountry }],
    () =>
      showRecentFeedUpdates(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null
  );

  useEffect(() => {
    const doShowTable =
      showRecentFeedUpdatesQuery.data?.data.showFeedUpdates ?? "N";
    setShowRecentFeedUpdatesTable(doShowTable === "Y");
  }, [showRecentFeedUpdatesQuery.data, setShowRecentFeedUpdatesTable]);
  useEffect(() => {
    if (menuItemsQuery.data?.data.pathList) {
      setUserMenuList(menuItemsQuery.data.data.pathList);
    }
  }, [menuItemsQuery.data, setUserMenuList]);

  const menuListItems: MenuListItem[] = useMemo(() => {
    if (menuItemsQuery.data?.data.dataList === undefined) {
      return [];
    }

    setDomain(menuItemsQuery.data?.data?.domain!);

    return menuItemsQuery.data.data.dataList.filter(
      (maybeMenuItem: any): maybeMenuItem is MenuListItem =>
        isMenuListItem(maybeMenuItem)
    );
  }, [menuItemsQuery.data, setDomain]);

  const visibleMenuItems: string[] = useMemo(() => {
    // exclude "Search" nav item
    const filteredMenuListItems = menuListItems.filter(
      (item: MenuListItem) => item.menuName.toLowerCase() !== "search"
    );
    const flatMenuNames = flattenMenuNames(filteredMenuListItems);
    if (!showRecentFeedUpdatesTable) {
      // if showRecentFeedUpdates is true, hide Bulk Upload nav item
      return flatMenuNames.filter((name: string) => name !== "bulk upload");
    }
    return flatMenuNames;
  }, [menuListItems, showRecentFeedUpdatesTable]);

  const getEmailPreferencesQuery = useCustomQuery(
    ["getEmailPreferences", { selectedCountry }],
    () =>
      getEmailPreferences(apiClient)({
        countryCode: selectedCountry!,
      }),
    selectedCountry !== null
  );
  const emailPreference: string | undefined = useMemo(() => {
    if (getEmailPreferencesQuery.data?.data.notification) {
      return getEmailPreferencesQuery.data?.data.notification;
    }
  }, [getEmailPreferencesQuery.data]);

  useEffect(() => {
    if (
      !accessToken &&
      (selectedCountry ||
        selectedRole ||
        defaultLanguage ||
        selectableLanguages ||
        isItemLevelMarket ||
        showRecentFeedUpdatesTable)
    ) {
      // access token expiring also expires country/role
      setSelectedCountry(null);
      setSelectedRole(null);
      setDefaultLanguage(null);
      setIsItemLevelMarket(null);
      setSelectableLanguages(null);
      setShowRecentFeedUpdatesTable(null);
    }
  }, [
    accessToken,
    selectedRole,
    selectedCountry,
    defaultLanguage,
    selectableLanguages,
    setSelectedCountry,
    setSelectedRole,
    isItemLevelMarket,

    showRecentFeedUpdatesTable,
    visibleMenuItems,
    setIsItemLevelMarket,
    setDefaultLanguage,
    setSelectableLanguages,
    setShowRecentFeedUpdatesTable,
  ]);

  const canEdit = useMemo(
    () => Boolean([2, 3, 4].includes(Number(selectedRole))),
    [selectedRole]
  );
  const isReaderRole = useMemo(
    () => Number(selectedRole) === 9,
    [selectedRole]
  );
  const isLegalRole = useMemo(() => Number(selectedRole) === 5, [selectedRole]);

  const value = useMemo(
    () => ({
      selectedCountry,
      selectedRole,
      defaultLanguage,
      selectableLanguages,
      selectedRoleName,
      canEdit,
      isReaderRole,
      isLegalRole,
      isItemLevelMarket,
      setIsItemLevelMarket,
      isMultipleRFMIds,
      showRecentFeedUpdates: showRecentFeedUpdatesTable,
      visibleMenuItems,
      isEmailPreferenceOn: emailPreference === "Y",
      setSelectedCountry,
      setSelectedRole,
      setSelectableRoles,
      setDefaultLanguage,
      setSelectableLanguages,
      setShowRecentFeedUpdatesTable,
      userMenuList,
    }),
    [
      selectedCountry,
      selectedRole,
      defaultLanguage,
      selectableLanguages,
      selectedRoleName,
      canEdit,
      isReaderRole,
      isLegalRole,
      isItemLevelMarket,
      visibleMenuItems,
      emailPreference,
      showRecentFeedUpdatesTable,
      setIsItemLevelMarket,
      isMultipleRFMIds,
      setSelectedCountry,
      setSelectedRole,
      setSelectableRoles,
      setDefaultLanguage,
      setSelectableLanguages,
      setShowRecentFeedUpdatesTable,
      userMenuList,
    ]
  );

  return <RoleContext.Provider value={value}>{outlet}</RoleContext.Provider>;
};
