import React, { useContext, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import {
  MenuList,
  Popover,
  Typography,
  InputBase,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataContext } from "../data-provider";
import { ItemWithCategories, createTopNMatchFn, ValidCategory } from "../utils";
import { useNavigate } from "react-router-dom";
import { SearchableProduct } from "../util/searchable-products";

const getTopTenProductMatches = createTopNMatchFn<SearchableProduct>(
  ["productName", "productId", "importId"],
  10
);
const getTopTenItemMatches = createTopNMatchFn<ItemWithCategories>(
  ["itemName", "menuItemNo","menuItemNo2","menuItemNo3"],
  10
);  // varshitha potala MENUTECH-341:- Added two more fields to display multiple RFM numbers in Global search
const getTopTenCategoryMatches = createTopNMatchFn<ValidCategory>(
  ["categoryName", "categoryId"],
  10
);

const PLACEHOLDER_TEXT = "Search Products, Items, Categories";

export const UniversalSearchBar: React.FC = () => {
  const navigate = useNavigate();
  const { products, items, categoryList } = useContext(DataContext);
  const [productSearchResults, setProductSearchResults] = useState<
    SearchableProduct[]
  >([]);
  const [itemSearchResults, setItemSearchResults] = useState<
    ItemWithCategories[]
  >([]);
  const [categorySearchResults, setCategorySearchResults] = useState<
    ValidCategory[]
  >([]);

  const [universalSearchInput, setUniversalSearchInput] = useState<string>("");
  const [searchPlaceholder, setSearchPlaceholder] =
    useState<string>(PLACEHOLDER_TEXT);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (universalSearchInput.length > 0 && inputRef.current) {
      setProductSearchResults(
        getTopTenProductMatches(products, universalSearchInput)
      );
      setItemSearchResults(getTopTenItemMatches(items, universalSearchInput));
      setCategorySearchResults(
        getTopTenCategoryMatches(categoryList, universalSearchInput)
      );
      setAnchorEl(inputRef.current);
    } else {
      setProductSearchResults([]);
      setAnchorEl(null);
    }
  }, [categoryList, items, products, universalSearchInput]);

  const handleNavigation = (product: SearchableProduct) => {
    navigate(`/products/${product.productId}/products-ingredients`);
  };

  return (
    <>
      <StyledSearchInput>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={searchPlaceholder}
          onFocus={() => setSearchPlaceholder("")}
          onBlur={() => {
            if (universalSearchInput.length === 0) {
              setSearchPlaceholder("Search");
            }
          }}
          inputRef={inputRef}
          inputProps={{
            "aria-label": `Search`,
            role: "search",
            "aria-haspopup": "listbox",
            "aria-expanded": anchorEl ? "true" : "false",
            "aria-owns": anchorEl ? "search-results" : undefined,
          }}
          value={universalSearchInput}
          onChange={(event) => {
            setUniversalSearchInput(event.target.value);
          }}
        />
      </StyledSearchInput>
      <Popover
        id="search-results"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 2 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <Paper sx={{ minWidth: 700 }}>
          <StyledMenuList role="listbox" aria-labelledby="search-results">
            <Grid key="Products">
              <StyledSearchResultGroupHeader container>
                <StyledSearchResultGroupTitle variant="h6">
                  Products
                </StyledSearchResultGroupTitle>
              </StyledSearchResultGroupHeader>
              {productSearchResults.length > 0 ? (
                <>
                  {productSearchResults.map((product, index: number) => (
                    <StyledMenuItem
                      role="option"
                      aria-describedby={`product-${index}`}
                      key={index}
                      onClick={() => handleNavigation(product)}
                    >
                      <StyledSearchResultNameField variant="body1">
                        {product.productName}
                      </StyledSearchResultNameField>
                      <StyledSearchResultIdField variant="body2">
                        {product.productId}
                      </StyledSearchResultIdField>
                      <StyledSearchResultIdField variant="body2">
                        {product.importId}
                      </StyledSearchResultIdField>
                    </StyledMenuItem>
                  ))}
                </>
              ) : (
                <StyledEmptyBox>
                  <Typography variant="h6">{`0 matching Products found`}</Typography>
                </StyledEmptyBox>
              )}
            </Grid>
            <Grid key="Items">
              <StyledSearchResultGroupHeader container>
                <StyledSearchResultGroupTitle variant="h6">
                  Items
                </StyledSearchResultGroupTitle>
              </StyledSearchResultGroupHeader>
              {itemSearchResults.length > 0 ? (
                <>
                  {itemSearchResults.map((item, index: number) => (
                    <StyledMenuItem
                      key={index}
                      onClick={() =>
                        navigate(
                          `/items/${item.itemId}/item-marketing-information`
                        )
                      }
                      role="option"
                      aria-describedby={`item-${index}`}
                    >
                      <StyledSearchResultNameField variant="body1">
                        {item.itemName}
                      </StyledSearchResultNameField>
                      <StyledSearchResultIdField variant="body2">
                        {item.itemId}
                      </StyledSearchResultIdField>
                      <StyledSearchResultIdField variant="body2">
                        {item.menuItemNo}
                      </StyledSearchResultIdField>
                     
                      <StyledSearchResultIdField variant="body2">
                        {item.menuItemNo2}
                      </StyledSearchResultIdField>
                      <StyledSearchResultIdField variant="body2">
                        {item.menuItemNo3}
                      </StyledSearchResultIdField>
                     
                    </StyledMenuItem>
                  ))}
                </>
              ) : (
                <StyledEmptyBox>
                  <Typography variant="h6">{`0 matching Items found`}</Typography>
                </StyledEmptyBox>
              )}
            </Grid>
            <Grid key="Categories">
              <StyledSearchResultGroupHeader container>
                <StyledSearchResultGroupTitle variant="h6">
                  Categories
                </StyledSearchResultGroupTitle>
              </StyledSearchResultGroupHeader>
              {categorySearchResults.length > 0 ? (
                <>
                  {categorySearchResults.map((category, index: number) => (
                    <StyledMenuItem
                      role="option"
                      aria-describedby={`product-${index}`}
                      key={index}
                      onClick={() =>
                        navigate(
                          `/categories/${category.categoryId}/category-marketing-information`
                        )
                      }
                    >
                      <StyledSearchResultNameField
                        id={`product-${index}`}
                        role="textbox"
                        variant="body1"
                      >
                        {category.categoryName}
                      </StyledSearchResultNameField>
                      <StyledSearchResultIdField
                        id={`product-id-${index}`}
                        role="textbox"
                        variant="body2"
                      >
                        {category.categoryId}
                      </StyledSearchResultIdField>
                    </StyledMenuItem>
                  ))}
                </>
              ) : (
                <StyledEmptyBox>
                  <Typography variant="h6">{`0 matching Categories found`}</Typography>
                </StyledEmptyBox>
              )}
            </Grid>
          </StyledMenuList>
        </Paper>
      </Popover>
    </>
  );
};
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 66,
  justifyContent: "center",
  alignItems: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
const StyledSearchInput = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexGrow: 1,
  marginRight: "20px",
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledSearchResultGroupHeader = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  justifyContent: "center",
}));
const StyledSearchResultGroupTitle = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));
const StyledSearchResultNameField = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));
const StyledSearchResultIdField = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: theme.palette.secondary.dark,
}));
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
}));
const StyledMenuItem = styled(MenuItem)({
  justifyContent: "space-between",
});
