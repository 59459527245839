import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControlLabel,
  TableHead,
  TableRow,
  Typography,
  styled,
  Checkbox,
  Alert,
  Badge,
  LinearProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DeleteOutline } from "@mui/icons-material";
import { withLayout } from "../hoc/with-layout";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation,  useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import {
  deletePushLive,
  getPushLiveDetails,
  saveReadyForBulkPushlive,
} from "../data/miscellaneous";
import { PushLiveDetailData, isElement } from "../utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RoleContext } from "../role-provider";
import { useForm } from "react-hook-form";
import { Loader } from "./loader/Loader";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";
import { EmptyState } from "./EmptyState";
import { AxiosError } from "axios";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { ResendFormModal } from "./ResendFormModal";
import { usePushLive } from "../push-live-provider";

export const PushLiveDetail = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { selectedCountry, selectedRole, isReaderRole, canEdit } =
    useContext(RoleContext);
  const { handleSubmit } = useForm();

  const [isReadyPushLiveModalOpen, setIsReadyPushLiveModalOpen] =
    useState(false);
  const [eventToDeleteId, setEventToDeleteId] = useState<string | null>(null);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] = useState(false);
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isEventPending } = usePushLive();

  
  const toggleReadyModal = () => {
    setIsReadyPushLiveModalOpen(!isReadyPushLiveModalOpen);
  };
  const handleClickDelete = (pushLiveEventId: string) => {
    setEventToDeleteId(pushLiveEventId);
  };
  const handleCloseConfirmation = () => {
    setEventToDeleteId(null);
  };

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };


  const pushLiveDetailQuery = useCustomQuery(
    ["getPushLiveDetails"],
    () =>
      getPushLiveDetails(apiClient)({
        countryCode: selectedCountry!,
      }),
      
  );
  
  // Detect transition from `isEventPending` true -> false
  useEffect(() => {
    if (!isEventPending) {
      pushLiveDetailQuery.refetch();
    }
    // eslint-disable-next-line
  }, [isEventPending]);


  const pushLives = useMemo(() => {
    if (pushLiveDetailQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validpushLives = pushLiveDetailQuery.data?.data.dataList.filter(
      (maybePushLives): maybePushLives is PushLiveDetailData => {
        return isElement(maybePushLives) === true;
      }
    );
    return validpushLives;
  }, [pushLiveDetailQuery.data]);


  const isFetching = useMemo(
    () => pushLiveDetailQuery.isFetching,
    [pushLiveDetailQuery.isFetching]
  );
  const isSuccess = useMemo(
    () => pushLiveDetailQuery.isSuccess,
    [pushLiveDetailQuery.isSuccess]
  );

  const saveReadyForBulkPushliveRequest = saveReadyForBulkPushlive(apiClient);
  const { mutate, isLoading: isSavingBulkPush } = useMutation(
    () => {
      const selectedTypes = {
        elementTypeList: selectedValues,
      };

      return saveReadyForBulkPushliveRequest(selectedTypes, {
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onMutate: () => saveReadyForBulkPushliveRequest,
      onSuccess: () => {
        setIsReadyPushLiveModalOpen(false);
        setSuccessMessage("Bulk Push Live was successful");
        queryClient.invalidateQueries(["getPushLiveDetails"]);
        queryClient.invalidateQueries(["getApprovedChanges"]);
        queryClient.invalidateQueries(["getUnapprovedChanges"]);
        queryClient.invalidateQueries(["getItemsList"]);
        queryClient.invalidateQueries(["getProductsList"]);
        queryClient.invalidateQueries(["getAllCategoriesList"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setTokenError(
            "An error occurred submitting the form. Please try again."
          );
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const doSaveReadyPushLive = (formData: any) => {
    mutate(formData);
  };
  const deleteMutation = useMutation(
    (pushLiveEventId: string) => {
      return deletePushLive(apiClient)({
        pushLiveEventId,
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage("Push Live Event deleted successfully");
        handleCloseConfirmation();
        queryClient.invalidateQueries(["getPushLiveDetails"]);
        queryClient.invalidateQueries(["getApprovedChanges"]);
        queryClient.invalidateQueries(["getUnapprovedChanges"]);
        queryClient.invalidateQueries(["editPushLive"]);
        queryClient.invalidateQueries(["getPushliveEventDetails"]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteModalOpen(true);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const doDeleteEvent = () => {
    if (typeof eventToDeleteId === "string") {
      deleteMutation.mutate(eventToDeleteId);
    }
  };
  const isStatusPending = pushLives.some((item) => item.status === "Pending");
  const isStatusProcessing = pushLives.some(
    (item) => item.status === "Processing"
  );

  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid container spacing={3}>
        <Grid container mobile={12} spacing={0}>
          {isStatusProcessing && (
            <Grid mobile={12}>
              <LinearProgress color="error" />
              <Alert severity="error">Push live is running</Alert>
            </Grid>
          )}
          <Grid mobile={12}>
            <Typography variant="h1" sx={{ margin: "32px 0 16px" }}>
              Push Live Detail
            </Typography>
          </Grid>
          <Grid container mobile={12} justifyContent="flex-end" sx={{ my: 2 }}>
            {!isFetching && canEdit && (
              <Button
                variant="contained"
                size="small"
                sx={{ mr: 2, backgroundColor: "#DA291C" }}
                disabled={ isStatusPending}
                onClick={() => {
                  toggleReadyModal();
                }}
              >
                Ready For Push Live
              </Button>
            )}
            {!isFetching && !isReaderRole && (
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  navigate("/push-live-detail/push-live-event");
                }}
                sx={{ backgroundColor: "#DA291C" }}
                disabled={ isStatusPending }
              >
                Create New Event
              </Button>
            )}
          </Grid>
          {(isFetching || isSavingBulkPush) && <Loader />}
          <Grid mobile={12}>
            <Stack spacing={1} marginBottom="20px">
              {!isFetching && isSuccess && (
                <form onSubmit={handleSubmit(doSaveReadyPushLive)}>
                  <TableContainer component={Paper}>
                    <Table aria-label="Category Mapping Table">
                      <StyledDashboardTableHead>
                        <TableRow>
                          <TableCell>Push Live Event ID</TableCell>
                          <TableCell>Push Live Event Name</TableCell>
                          <TableCell>Initiated By</TableCell>
                          <TableCell>Scheduled Time</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </StyledDashboardTableHead>
                      <TableBody>
                        {pushLives.length > 0 ? (
                          pushLives.map((item, index) => {
                            const isDisabled = item.status === "Failure";
                            return (
                              <StyledTableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={index}
                              >
                                <TableCell component="th" scope="row">
                                {isDisabled ? (
                                  <span>{item.pushLiveEventId}</span>
                                 ) : (
                                  <Link
                                    to={
                                      item.status === "Pending"
                                        ? `/push-live-detail/${item.pushLiveEventId}/edit-push-live-event`
                                        : `/push-live-detail/${item.pushLiveEventId}/push-live-event-details`
                                    }
                                  >
                                    {item.pushLiveEventId}
                                  </Link> ) }
                                </TableCell>
                                <TableCell>{item.pushLiveEventName}</TableCell>
                                <TableCell>{item.initiatedBy}</TableCell>
                                <TableCell>{item.scheduledTime}</TableCell>
                                <TableCell>
                                  {item.status === "Processing" ? (
                                    <Grid
                                      container
                                      mobile={12}
                                      justifyContent="center"
                                    >
                                      <Badge
                                        color="error"
                                        badgeContent={
                                          <Typography
                                            variant="body2"
                                            color="#ffffff"
                                          >
                                            {item.status}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                  ) : (
                                    item.status
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {item.status === "Pending" &&
                                      !isReaderRole && (
                                        <>
                                          <IconButton
                                            onClick={() => {
                                              navigate(
                                                `/push-live-detail/${item.pushLiveEventId}/edit-push-live-event`
                                              );
                                            }}
                                          >
                                            <EditIcon aria-label="edit Mutex button" />
                                          </IconButton>
                                        </>
                                      )}
                                    {!isReaderRole && (
                                      <IconButton
                                        onClick={() =>
                                          handleClickDelete(
                                            item.pushLiveEventId!
                                          )
                                        }
                                      >
                                        <DeleteOutline
                                          sx={{ color: "#DA291C" }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                </TableCell>
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <EmptyState>
                            No push live event has been scheduled!
                          </EmptyState>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </form>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <form
        onSubmit={handleSubmit(() => {
          setTokenError(null);
          doSaveReadyPushLive({ elementTypeList: selectedValues });
        })}
      >
        <Dialog
          open={isReadyPushLiveModalOpen}
          onClose={toggleReadyModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Ready for Bulk Push Live
          </DialogTitle>
          <DialogContent>
            {tokenError && (
              <Grid>
                <Alert variant="outlined" severity="error">
                  {tokenError}
                </Alert>
              </Grid>
            )}
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    value="category"
                    onChange={handleCheckboxChange}
                    checked={selectedValues.includes("category")}
                  />
                }
                label="Category"
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    value="item"
                    onChange={handleCheckboxChange}
                    checked={selectedValues.includes("item")}
                  />
                }
                label="Item"
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    value="product"
                    onChange={handleCheckboxChange}
                    checked={selectedValues.includes("product")}
                  />
                }
                label="Product"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setTokenError(null);
                toggleReadyModal();
              }}
            >
              <Typography>Cancel</Typography>
            </Button>
            <Button
              autoFocus
              variant="contained"
              type="submit"
              onClick={() => {
                setTokenError(null);
                doSaveReadyPushLive({ elementTypeList: selectedValues });
              }}
            >
              <Typography>Save</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      {typeof eventToDeleteId === "string" && (
        <ConfirmActionModal
          open={!isRetryDeleteModalOpen}
          loading={deleteMutation.isLoading}
          message="Are you sure you want to delete this push live event?"
          onConfirm={doDeleteEvent}
          onCancel={() => setEventToDeleteId(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteModalOpen}
        onResend={() => {
          setIsRetryDeleteModalOpen(false);
          doDeleteEvent();
        }}
        onCancel={() => {
          setEventToDeleteId(null);
          setIsRetryDeleteModalOpen(false);
        }}
        description="An error occurred while deleting the push live event"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Push Live Detail");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
