import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPushLiveDetails } from "./data/miscellaneous"; // Adjust according to your structure
import { RoleContext } from "./role-provider";
import { useAxios } from "./axios-provider";
import { useOutlet } from "react-router-dom";

interface PushLiveContextType {
    isEventPending: boolean;
    refetchPushLiveDetails: () => void; // Expose refetch for manual usage
}

const PushLiveContext = createContext<PushLiveContextType | undefined>(undefined);


export const PushLiveProvider = () => {
    const [isEventPending, setIsEventPending] = useState<boolean>(false);
    const { selectedCountry } = useContext(RoleContext); // Assuming you have a RoleContext that provides selectedCountry
    const { apiClient } = useAxios();
    const outlet = useOutlet();
    


    // Fetch push live event details
    const { data, refetch } = useQuery(
        ["getPushLiveDetails", selectedCountry],
        () => getPushLiveDetails(apiClient)({ countryCode: selectedCountry! }), // Pass parameters as needed
        {
            refetchInterval: isEventPending ? 60000 : false,  // Short expiry if event is pending or in progress
            enabled: !!selectedCountry,  // Ensure the query only runs if the selected country is available
            refetchOnWindowFocus: false, // Disable refetch on window focus
            refetchOnReconnect: false, // Disable refetch on reconnect                                                
        }
    );

    // Check if any event is Pending or In Progress
    useEffect(() => {
        const hasPendingOrInProgressEvent = data?.data?.dataList?.some(
            (event) => event.status === "Pending" || event.status === "Processing"
        );
    
          if (isEventPending !== !!hasPendingOrInProgressEvent) {
              setIsEventPending(!!hasPendingOrInProgressEvent);
          }
    
       // eslint-disable-next-line
    }, [data]);

    const value = useMemo(
        () => ({
            selectedCountry,
            isEventPending,
            setIsEventPending,
            refetchPushLiveDetails: refetch, // Expose refetch method
        }),
        [
            selectedCountry,
            isEventPending,
            setIsEventPending,
            refetch,
        ]
    );
    return <PushLiveContext.Provider value={value}>{outlet}</PushLiveContext.Provider>;
};

export const usePushLive = (): PushLiveContextType => {
    const context = useContext(PushLiveContext);
    if (!context) {
        throw new Error("usePushLive must be used within a PushLiveProvider");
    }
    return context;
};
